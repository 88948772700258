import axios from "axios";

console.log(process.env.VUE_APP_ENDPOINT_URL);
export default {
    endpoint: process.env.VUE_APP_ENDPOINT_URL,
    apiFaceswapVerb: "faceswap",
    apiKitchosVerb: "kitchos",

    /**
     * Upload and swap image
     * @param kitchoId
     * @param file
     */
    swapFromFile(kitchoId, file) {
        const formData = new FormData();
        formData.append("image", file, "image.jpg");
        formData.append("kitchoId", kitchoId);

        return axios
            .post(this.endpoint + this.apiFaceswapVerb, formData)
            .catch(() => {
                alert(
                    "Désolé aucun visage n'a pu être détecté. Merci de réessayer"
                );
            });
    },

    /**
     * Swap image already uploaded on server
     * @param kitchoId
     * @param uploadId
     * @return {*|AxiosPromise}
     */
    swapFromUploadId(kitchoId, uploadId) {
        const formData = new FormData();
        formData.append("kitchoId", kitchoId);
        formData.append("uploadId", uploadId);
        return axios.post(this.endpoint + this.apiFaceswapVerb, formData);
    },

    /**
     * Call the service to get the kitchos
     * @return {Promise.<TResult>}
     */
    getKitchos() {
        return axios
            .get(this.endpoint + this.apiKitchosVerb)
            .then((response) => response.data)
            .catch(() => {
                alert("Erreur: le serveur est resté bloqué en 1980");
            });
    },
};
