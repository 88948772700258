import { EXIF } from "@/app/services/Exif";

export default {
    /**
     * Load one image
     * @param file
     * @return Promise
     */
    loadImage(file) {
        return new Promise((resolve) => {
            const fileReader = new FileReader();

            fileReader.onload = () => {
                const userImage = new Image();
                userImage.onload = () => {
                    resolve(userImage);
                };
                userImage.src = fileReader.result;
            };

            fileReader.readAsDataURL(file);
        });
    },

    /**
     * Transform data URL to Blob
     */
    transformDataUrlToBlob: function (dataURL) {
        const blobBin = atob(dataURL.split(",")[1]);
        const array = [];

        for (let i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
    },

    drawImage(file, maxDimension, canvas, context) {
        return this.loadImage(file).then((userImage) => {
            return new Promise((resolve) => {
                return EXIF.getData(userImage, () => {
                    const orientation = EXIF.getTag(userImage, "Orientation");
                    const rotate = orientation === 8 || orientation === 6;

                    // Calculate image size
                    let newImageWidth;
                    let newImageHeight;

                    let imageWidth = rotate
                        ? userImage.height
                        : userImage.width;
                    let imageHeight = rotate
                        ? userImage.width
                        : userImage.height;

                    newImageWidth = imageWidth;
                    newImageHeight = imageHeight;

                    if (imageWidth > imageHeight && imageWidth > maxDimension) {
                        newImageWidth = maxDimension;
                        newImageHeight =
                            (newImageWidth * imageHeight) / imageWidth;
                    } else if (
                        imageHeight > imageWidth &&
                        imageHeight > maxDimension
                    ) {
                        newImageHeight = maxDimension;
                        newImageWidth =
                            (newImageHeight * imageWidth) / imageHeight;
                    }

                    // Load image to canvas
                    canvas.width = newImageWidth;
                    canvas.height = newImageHeight;

                    if (rotate) {
                        let rotateDirection = -1;

                        if (orientation === 6) {
                            rotateDirection = 1;
                        }

                        context.translate(
                            newImageWidth / 2,
                            newImageHeight / 2
                        );
                        context.rotate((Math.PI * rotateDirection) / 2);
                        context.translate(
                            -newImageHeight / 2,
                            -newImageWidth / 2
                        );
                    }
                    context.drawImage(
                        userImage,
                        0,
                        0,
                        rotate ? newImageHeight : newImageWidth,
                        rotate ? newImageWidth : newImageHeight
                    );

                    resolve();
                });
            });
        });
    },
};
