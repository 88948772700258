<template>
    <div class="index-page" ref="indexPage">
        <div class="index-page__intro" ref="intro">
            <div class="index-page__logo-intro" ref="logo"></div>
            <div class="index-page__itchomaton">
                <div
                    class="index-page__itchomaton-content"
                    ref="itchomatonContent"
                >
                    <div class="index-page__inner" ref="itchomatonInner">
                        <div class="index-page__itch"></div>
                        <div class="index-page__o"></div>
                        <div class="index-page__maton"></div>
                    </div>
                </div>
                <div class="index-page__advice" ref="advice">
                    Pour une expérience encore plus incroyable,
                    <strong>utilisez votre smartphone</strong>
                </div>
            </div>
        </div>
        <div class="index-page__content" ref="pageContent">
            <div class="index-page__logo" ref="pageLogo"></div>
            <photo-component
                class="index-page__photo-component"
                ref="photoComponent"
                :mute="mute"
            />
            <div class="index-page__shared-image" ref="sharedImage">
                <img src="" ref="sharedImageContent" />
                <a
                    href=""
                    class="button"
                    @click.prevent="switchToPhotoComponent"
                    >à mon tour !</a
                >
            </div>
        </div>

        <div class="footer-component index-page__footer">
            <div class="footer-component__left">
                <router-link :to="{ name: 'legal-terms' }"
                    >Mentions légales</router-link
                >
            </div>
            <div class="footer-component__right">
                réalisé par &nbsp;<a
                    href="//www.lahautesociete.com"
                    target="_blank"
                    >La Haute Société</a
                >
                <div
                    class="footer-component__sound"
                    ref="soundMute"
                    @click.prevent="toggleMute"
                >
                    <div class="footer-component__sound-on" v-if="!mute"></div>
                    <div class="footer-component__sound-off" v-if="mute"></div>
                </div>
            </div>
        </div>

        <div class="index-page__orientation">
            <div class="index-page__orientation-icon"></div>
            <br />
            Tournez votre smartphone
        </div>

        <div class="index-page__surprise" ref="konami">
            <video class="index-page__video" ref="video" autoplay loop></video>
        </div>
    </div>
</template>

<script>
import Konami from "konami";
import PhotoComponent from "@/app/components/PhotoComponent";
import { gsap, Power1, Power2, Power3 } from "gsap";

/* globals SHARED_VALUES */
export default {
    name: "index-page",

    data() {
        return {
            mention: false,
            mute: false,
            konamied: false,
        };
    },

    computed: {
        isMobile() {
            return window.innerWidth <= 768;
        },
    },

    components: {
        PhotoComponent,
    },

    methods: {
        toggleMute() {
            this.mute = !this.mute;
        },

        /**
         * Update the background gradient
         * @param pFrom
         * @param pTo
         */
        updateBackground(pBackgroundImage, pBackgroundColor) {
            gsap.to(document.querySelector(".index-page"), {
                duration: 3,
                backgroundColor: pBackgroundColor,
                backgroundImage: pBackgroundImage,
                ease: Power3.easeOut,
            });
        },

        /**
         * Intro animation
         */
        playIntro() {
            let introTl = new gsap.timeline();

            if (!this.isMobile) {
                // Put the logo on the left
                introTl.set(this.$refs.logo, {
                    opacity: 0,
                });

                // Show itchomaton
                introTl.to(this.$refs.itchomatonContent, 1, {
                    width: this.$refs.itchomatonInner.clientWidth,
                    ease: Power1.easeIn,
                    delay: 0.5,
                });

                // Hide itchomaton
                introTl.to(
                    this.$refs.itchomatonContent,
                    1,
                    {
                        width: 0,
                        display: "none",
                        ease: Power3.easeIn,
                    },
                    "+=1"
                );

                // Show the advice
                introTl.to(
                    this.$refs.advice,
                    0.4,
                    {
                        opacity: 1,
                    },
                    "+=0.5"
                );

                // Hide the advice
                introTl.to(this.$refs.advice, 0.4, {
                    opacity: 0,
                    delay: 2,
                });
            }

            // If we are on mobile
            if (this.isMobile) {
                // Hide itchomaton
                introTl.set(this.$refs.itchomatonContent, {
                    display: "none",
                });

                // Hide logo
                introTl.to(
                    this.$refs.logo,
                    0.8,
                    {
                        opacity: 0,
                        ease: Power2.easeOut,
                    },
                    "+=1"
                );
            }

            // Show page content
            introTl.to(this.$refs.pageContent, 1, {
                height: "100%",
                opacity: 1,
                ease: Power2.easeOut,
            });

            // Show true logo
            introTl.to(this.$refs.pageLogo, 0.5, {
                y: 0,
                ease: Power3.easeOut,
            });

            // Hide everything in intro
            introTl.set(this.$refs.intro, {
                display: "none",
            });
        },

        /**
         * Show the shared image in parameter
         */
        showSharedImage() {
            const sharedTl = new gsap.timeline();

            // Hide itchomaton content
            sharedTl.set(this.$refs.itchomatonContent, {
                display: "none",
            });

            // Hide logo
            sharedTl.to(
                this.$refs.logo,
                0.8,
                {
                    opacity: 0,
                    ease: Power2.easeOut,
                },
                "+=1"
            );

            // Hide photo component
            sharedTl.set(document.querySelector(".photo-component"), {
                display: "none",
                opacity: 0,
            });

            // Show the shared image
            sharedTl.set(this.$refs.sharedImage, {
                display: "block",
                opacity: 1,
            });

            // Show the page content
            sharedTl.to(this.$refs.pageContent, 1, {
                height: "100%",
                opacity: 1,
                ease: Power2.easeOut,
            });

            // Show the true logo
            sharedTl.to(this.$refs.pageLogo, 0.5, {
                y: 0,
                ease: Power3.easeOut,
            });

            // Hide everything from the intro
            sharedTl
                .set(this.$refs.intro, {
                    display: "none",
                })
                .play();

            // Load the image
            this.$refs.sharedImageContent.setAttribute(
                "src",
                SHARED_VALUES.imageUrl
            );
        },

        /**
         * Hide the shared image to show the photo componenet
         */
        switchToPhotoComponent() {
            // Hide the shared image
            gsap.to(this.$refs.sharedImage, {
                duration: 0.4,
                display: "none",
                opacity: 0,
                onComplete: () => {
                    // Show the photo component
                    gsap.to(document.querySelector(".photo-component"), {
                        duration: 0.5,
                        display: "block",
                        opacity: 1,
                    });
                },
            });
        },

        updateLogoSize() {
            gsap.set(this.$refs.pageLogo, {
                scale: window.innerWidth <= 559 ? 0.5 : 1,
            });
        },

        toggleMention() {
            gsap.to(document.querySelector(".index-page__photo-component"), {
                duration: 0.4,
                opacity: !this.mention ? 0 : 1,
                display: !this.mention ? "none" : "flex",
            });

            gsap.to(this.$refs.mentionContent, {
                duration: 0.4,
                opacity: !this.mention ? 1 : 0,
                display: !this.mention ? "flex" : "none",
            });

            this.mention = !this.mention;
        },

        doKonami() {
            // If konami isn't alreay running
            if (!this.konamied) {
                // Set the source
                this.$refs.video.setAttribute("src", "video/kitchonami.mp4");

                // Show the container
                gsap.set(this.$refs.konami, { display: "block" });
            }
        },
    },

    mounted() {
        // Analytics
        this.$ga.page({
            page: "/",
            title: "Home page",
            location: window.location.href,
        });

        // Analytics
        this.$ga.page({
            page: "/",
            title: "Home page",
            location: window.location.href,
        });

        // Event for background changing
        this.$refs.photoComponent.$on(
            "COLOR_CHANGE",
            (backgroundImage, backgroundColor) =>
                this.updateBackground(backgroundImage, backgroundColor)
        );

        // PLAY INTRO
        if (typeof SHARED_VALUES !== "undefined") {
            console.log("SHARED", SHARED_VALUES);

            this.showSharedImage();
        } else {
            this.playIntro();
        }

        window.addEventListener("resize", () => {
            this.updateLogoSize();
        });

        this.updateLogoSize();

        new Konami(this.doKonami);
    },
};
</script>

<style scoped lang="scss">
@import "~@/styles/main.scss";

.index-page {
    position: relative;
    @include size(100%, auto);
    min-height: 100%;
    overflow: hidden;
    background-image: linear-gradient(
        0deg,
        rgba(103, 254, 198, 1) 0%,
        rgba(253, 147, 240, 1) 100%
    );
    padding-bottom: 3%;

    // INTRO

    &__intro {
        @include flex(column, center, flex-start);
        @include place(0, 0, 0, 0);
        padding-bottom: 75px;
    }

    &__logo-intro {
        @include sprite;
        @include sprite-logo-kitcho-big(0.5);
        margin: 0 auto;
        margin-top: 25px;
    }

    &__itchomaton {
        @include place(0, 0, 0, 0);
        @include flexCenter(row);
    }

    &__itchomaton-content {
        @include flex(row, center, flex-start);
        overflow: hidden;
        width: 0;
        position: absolute;
        padding-top: 25px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        > * {
            flex-shrink: 0;
        }
    }

    &__itch {
        @include sprite;
        @include sprite-itch(0.5);
    }

    &__o {
        position: relative;
        @include sprite;
        @include sprite-circle-intro(0.5);
        margin: 0 10px;
        top: -7px;

        animation-duration: 10s;
        animation-name: rotate;
        animation-iteration-count: infinite;
        animation-direction: alternate;

        @keyframes rotate {
            from {
                transform: rotate(0);
            }

            to {
                transform: rotate(360deg);
            }
        }
    }

    &__maton {
        position: relative;
        @include sprite;
        @include sprite-maton(0.5);
        margin-top: -5px;
        top: -15px;
    }

    &__advice {
        margin-top: 20px;
        text-align: center;
        @extend %arial;
        font-size: 18px;
        opacity: 0;
    }

    // CONTENT

    &__logo {
        @include sprite;
        @include sprite-logo-kitcho(0.5);
        transform: translateY(-200px);
        margin: 5% auto 22px;

        @media (max-width: $mobile-max-breakpoint) {
            transform: scale(0.5);
            margin: -15px auto 0px;
        }
    }

    &__content {
        @include flex(column, flex-start, center);

        opacity: 0;
        height: 0;
        overflow: hidden;
    }

    &__photo-component {
        @media (max-width: $mobile-max-breakpoint) {
            margin-top: -10px;
        }
    }

    &__shared-image {
        display: none;
        opacity: 0;

        img {
            max-width: 720px;
            margin: 0 auto;
            border: 2px solid $color-white;

            @media (max-width: $mobile-max-breakpoint) {
                max-width: calc(100% - 40px);
                margin: 0 20px;
            }
        }
    }

    // FOOTER

    &__footer {
        box-sizing: border-box;
        width: 100%;
        @include bottomLeft(40px, 50%);
        transform: translateX(-50%);
    }

    &__orientation {
        @include size(100%, 100%);
        @include place(0, 0, 0, 0);

        @extend %arial-bold;
        font-size: 24px;
        text-align: center;
        @include disableSelection;

        @include flexCenter(column);

        display: none;
    }

    &__orientation-icon {
        @include sprite;
        @include sprite-orientation(0.5);
        margin-bottom: 35px;
    }

    @media (orientation: landscape) and (max-width: 720px) {
        &__orientation {
            display: flex;
        }

        &__intro,
        &__content,
        &__footer,
        &__logo {
            display: none;
        }
    }

    &__surprise {
        @include place(0, 0, 0, 0);
        z-index: 10000;
        background-color: $color-black;
        display: none;
    }

    &__video {
        @include topLeft(50%, 50%);
        transform: translate(-50%, -50%);
        min-height: 100%;
        min-width: 100%;
    }
}
</style>
