import Vue from "vue";
import App from "@/app/App";
import router from "@/app/plugins/routes.js";
import VueAnalytics from "vue-analytics";

import { gsap } from "gsap";
import { EaselPlugin } from "gsap/EaselPlugin";
gsap.registerPlugin(EaselPlugin);

const VueTouch = require("vue-touch");
Vue.use(VueTouch, { name: "v-touch" });

Vue.use(VueAnalytics, {
    id: "UA-113237662-1",
});

new Vue({
    el: "#app",
    router,
    template: "<App />",
    components: { App },
});
