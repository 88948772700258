<template>
    <div class="cnil-component CnilKit" CnilKit ref="cnilKit" v-if="show">
        <div class="CnilKit_presentation">
            {{ content.cookies }}
            <a
                href="#"
                class="link CnilKit_link"
                CnilKit_link
                @click.prevent="open"
            >
                {{ content.knowMore }}
            </a>
            <a
                href="#"
                class="link CnilKit_close"
                CnilKit_close
                @click.prevent="close"
            ></a>
        </div>
        <div class="CnilKit_more">
            {{ content.paragraph }}
        </div>
    </div>
</template>

<script>
export default {
    name: "cnil-component",

    data() {
        return {
            cookieName: "cnil-message-displayed",

            show: false,

            contentByLanguage: {
                en: {
                    cookies:
                        "Our website uses cookies in order to improve your user experience. ",
                    knowMore: "More about cookies",
                    paragraph:
                        "This website uses cookies from Google Analytics in order to help us identify the content which interests you. Cookies are also used to identify operating dysfunctions. Our website's data collection is sent to Google Inc.",
                },
                fr: {
                    cookies:
                        "Nous utilisons des cookies pour vous offrir la meilleure expérience utilisateur.",
                    knowMore: "En savoir plus",
                    paragraph:
                        "Ce site utilise des cookies de Google Analytics. Ces cookies nous aident à identifier le contenu qui vous intéresse le plus ainsi qu’à repérer certains dysfonctionnements. Vos données de navigation sur ce site sont envoyées à Google Inc.",
                },
                it: {
                    cookies:
                        "Il nostro sito utilizza i “cookies” per migliorare la tua esperienza di navigazione",
                    knowMore: "Leggi sui cookies",
                    paragraph:
                        'Questo sito web utilizza il servizio Google Analytics, i loro “cookies” ci aiutano ad identificare i contenuti di tuo interesse e vengono utilizzati per identificare le funzionalità operative del sito. Le informazioni generate dai "cookies" sull\'utilizzo del nostro sito web verranno trasmesse presso i server di Google Inc.',
                },
                de: {
                    cookies:
                        "Unsere Webseite benutzt Cookies um Inhalte personalisiert anzuzeigen.",
                    knowMore: "Mehr zu Cookies",
                    paragraph:
                        "Zusätzlich werden Cookies von Google Analytics benutzt, um Ihnen den Inhalt bereitzustellen, der Sie interessiert. Mit der Nutzung dieser Webseite erklären Sie sich damit einverstanden, dass die Cookies verwendet werden.",
                },
                be: {
                    cookies:
                        "We'd like to use cookies which help us understand how this website is used by our visitors. You can find out more about these cookies",
                    knowMore: "here",
                    paragraph:
                        "By continuing to use this site you are giving us your consent to do this.",
                },
            },

            content: {
                cookies: "",
                knowMore: "",
                paragraph: "",
            },
        };
    },

    computed: {
        language() {
            let language = navigator.language || navigator.browserLanguage;

            if (!(language in this.contentByLanguage)) {
                language = "en";
            }

            return language;
        },
    },

    methods: {
        /**
         * Get the cookie if there's one
         * @returns {*}
         */
        getCookie(name) {
            name = name + "=";
            var ca = document.cookie.split(";");
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == " ") c = c.substring(1);
                if (c.indexOf(name) == 0)
                    return c.substring(name.length, c.length);
            }
            return false;
        },

        /**
         * Set the cookie
         */
        setCookie(name, value, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
            var expires = "expires=" + d.toUTCString();
            document.cookie = name + "=" + value + "; " + expires;
        },

        open() {
            this.$refs.cnilKit.classList.toggle("CnilKit-opened");
        },

        close() {
            this.$refs.cnilKit.remove();
            this.$ga.event("action", "close-cnil-message");
        },
    },

    mounted() {
        this.content = this.contentByLanguage[this.language];

        if (this.getCookie(this.cookieName)) {
            return;
        }

        this.show = true;

        this.setCookie(this.cookieName, 1, 365);
    },
};
</script>

<style scoped lang="scss">
.CnilKit {
    z-index: 9999;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background: #fd93f0;
    text-align: center;
    color: #ffffff;
    font-family: arial, sans-serif;
}
.CnilKit_presentation {
    background: #fd93f0;
    font-size: 11px;
    padding: 8px 10px;
}
.CnilKit_link {
    display: inline-block;
    color: #ffffff !important;
    text-decoration: underline;
}
.CnilKit_link:active,
.CnilKit_link:focus {
    outline: none;
}
.CnilKit_more {
    display: none;
}
.CnilKit-opened .CnilKit_more {
    display: block;
}
.CnilKit_more {
    color: #ffffff;
    font-size: 10px;
    line-height: 15px;
    padding: 15px 10px;
    max-width: 660px;
    margin: 0 auto;
}
.CnilKit_close {
    display: inline-block;
    margin-left: 7px;
    position: relative;
    top: 2px;
    width: 9px;
    height: 9px;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjQ0RkM0MDVCMDg3OTExRTVBQThBRDE2OTJCRDkwQjAwIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjQ0RkM0MDVDMDg3OTExRTVBQThBRDE2OTJCRDkwQjAwIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NDRGQzQwNTkwODc5MTFFNUFBOEFEMTY5MkJEOTBCMDAiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NDRGQzQwNUEwODc5MTFFNUFBOEFEMTY5MkJEOTBCMDAiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6nk9k+AAAAXElEQVR42mL8////TAYGhnNADKLRQToQGzIAFWUA8V8gTgdiBiScDhXPgAmgK4QrAPGRdcIULkFWgK4IhJf+h4AlyOLY3LAU3Y3oCtKxuRGbAgzPMBIRTkYAAQYAv83e8mEUjQAAAAAASUVORK5CYII=);
}
</style>
