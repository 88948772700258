<template>
    <div class="legal-terms-page">
        <div class="legal-terms-page__content" ref="pageContent">
            <div class="legal-terms-page__logo" ref="pageLogo"></div>
            <div class="legal-terms-page__mention-content" ref="mentionContent">
                <div>
                    Kitchomaton et Kitchomaton.fr<br />
                    Tous droits réservés La Haute Société 2018<br /><br />

                    <strong>Conditions d'utilisation</strong><br />
                    En utilisant le site kitchomaton.fr et ses services, vous
                    acceptez d'être lié par les Conditions d'utilisation
                    suivantes.<br />
                    La Haute Société se réserve le droit de mettre à jour et de
                    modifier les Conditions d'utilisation sans préavis. Toutes
                    les nouvelles fonctionnalités qui augmentent ou améliorent
                    le service actuel, sont soumises aux Conditions
                    d'utilisation. L'utilisation continue du Service après de
                    tels changements constitue votre consentement à de tels
                    changements. Vous pouvez consulter la version la plus
                    récente des Conditions d'utilisation à tout moment sur:
                    <a href="//kitchomaton.fr/#/mentions-legales"
                        >kitchomaton.fr/#/mentions-legales</a
                    ><br /><br />

                    <strong>Ce site</strong><br />
                    Vous acceptez de ne prendre aucune mesure susceptible de
                    compromettre la sécurité du site, de rendre le site
                    inaccessible aux autres ou d'endommager le site ou le
                    contenu. Vous vous engagez à ne pas ajouter, soustraire ou
                    modifier le contenu ou à tenter d'accéder à un contenu qui
                    ne vous est pas destiné. Vous acceptez de ne pas utiliser le
                    site d'une manière qui pourrait interférer avec les droits
                    de tiers. Pour accéder à ce site ou à certaines des
                    ressources qu'il a à offrir, il se peut qu'on vous demande
                    de fournir certains détails d'inscription ou d'autres
                    informations. Toutes les informations que vous fournissez
                    sur ce site devront être correctes, à jour et complètes.<br /><br />

                    <strong>Droits d'auteur et droits d'utilisation</strong
                    ><br />
                    Vous ne pouvez pas afficher, modifier, distribuer ou
                    reproduire de quelque façon que ce soit des documents
                    protégés par des droits d'auteur ou d'autres documents sans
                    avoir obtenu le consentement écrit préalable du détenteur
                    des droits d'auteur de ces documents.<br />
                    Le site Web kitchomaton.fr ne peut être utilisé qu'à des
                    fins légitimes et non commerciales.<br />
                    La transmission, la distribution ou le stockage de tout
                    matériel en violation de toute loi ou réglementation
                    applicable est interdite. Ceci inclut, sans limitation, le
                    matériel protégé par un brevet, un droit d'auteur, une
                    marque de commerce, une marque de service, un secret
                    commercial ou d'autres droits de propriété intellectuelle,
                    et/ou de droits à l'image des personnes. Si vous utilisez le
                    matériel d'une autre partie, vous devez obtenir une
                    autorisation préalable. En utilisant nos services, vous
                    déclarez et garantissez que vous êtes l'auteur et le
                    détenteur des droits d'auteur et/ou le détenteur de licence
                    approprié en ce qui concerne tout contenu hébergé, et vous
                    déclarez et garantissez qu'aucun contenu ne viole la marque
                    ou les droits d'un tiers. La Haute Société se réserve le
                    droit de suspendre ou de résilier les transmissions d'un
                    utilisateur qui, à notre discrétion, viole ces politiques ou
                    viole toute loi ou règlement.<br /><br />

                    Si vous estimez qu'un Contenu a été copié et publié sur le
                    site kitchomaton.fr d'une manière qui constitue une
                    violation du droit d'auteur ou de la marque, veuillez
                    fournir à La Haute Société les informations suivantes : une
                    signature électronique ou physique de la personne autorisée
                    à agir au nom du détenteur des droits d'auteur; une
                    description de l'œuvre protégée par les droits d'auteur que
                    vous prétendez avoir violée; une description de l'endroit où
                    le contenu que vous prétendez enfreindre se trouve sur le
                    site Web; votre adresse, numéro de téléphone et adresse
                    électronique; une déclaration écrite de votre part indiquant
                    que vous croyez de bonne foi que l'utilisation contestée
                    n'est pas autorisée par le titulaire du droit d'auteur, son
                    agent/représentant ou la loi; une déclaration de votre part,
                    faite sous peine de parjure, que les informations ci-dessus
                    dans votre avis sont exactes et que vous êtes le
                    propriétaire du droit d'auteur ou autorisé à agir au nom du
                    propriétaire du droit d'auteur.<br /><br />

                    Vous comprenez que lorsque vous utilisez le site
                    kitchomaton.fr, vous serez exposé à des soumissions
                    d'utilisateurs provenant de diverses sources et que La Haute
                    Société n’est pas responsable de l'exactitude, de l'utilité,
                    de la sécurité ou des droits de propriété intellectuelle de
                    ces soumissions.<br />
                    Vous comprenez et reconnaissez également que vous pouvez
                    être exposé à des soumissions d'utilisateur qui sont
                    inexactes, offensantes, indécentes ou répréhensibles. Vous
                    acceptez à ce titre de renoncer à tous droits ou recours
                    légaux ou équitables contre La Haute Société à cet égard; et
                    acceptez de dégager La Haute Société/kitchomaton.fr de toute
                    réclamation à ce sujet.<br />
                    Vous comprenez et acceptez que La Haute Société ne peut pas
                    être responsable du contenu soumis sur kitchomaton.fr, et
                    comprenez que vous pouvez néanmoins être exposé à de tels
                    matériaux. Vous accordez à kitchomaton.fr le droit libre,
                    perpétuel, irrévocable, mondial, non exclusif et autorisant
                    l'utilisation, la reproduction, la modification,
                    l'adaptation, la publication, la création de travaux
                    dérivés, la distribution, l'exécution et l'affichage de tout
                    contenu, des idées, des graphiques ou d'autres informations
                    communiquées à La Haute Société/kitchomaton.fr par le biais
                    de ce site (ci-après dénommé "Soumission") et d'incorporer
                    toute Soumission dans d'autres œuvres sous quelque forme,
                    support ou technologie que ce soit.<br />
                    En faisant une Soumission, vous garantissez à La Haute
                    Société que vous avez le droit légal d'afficher le contenu
                    dans la Soumission et que cela ne violera aucune loi ou les
                    droits de toute personne ou entité.<br />
                    Vous acceptez que toute photo téléchargée et modifiée par le
                    service kitchomaton.fr puisse être partagée sur les réseaux
                    sociaux existants et à venir, tels que Facebook, Twitter,
                    Instagram, Snapchat (liste non limitative); en vous
                    interdisant tout recours contre La Haute Société sur ces
                    partages.<br /><br />

                    Vos photos téléchargées et vos soumissions seront stockées
                    sur nos serveurs pour une durée maximale de 24 mois.<br /><br />

                    <strong>Résiliation</strong><br />
                    La Haute Société se réserve le droit de décider si le
                    contenu ou une soumission d'utilisateur est approprié et
                    conforme aux Conditions d'utilisation pour des violations
                    autres que la violation du droit d'auteur et des violations
                    du droit de la propriété intellectuelle, telles que, mais
                    sans s'y limiter, les lois réglementant le matériel obscène
                    ou diffamatoire. La Haute Société peut supprimer ces
                    soumissions d'utilisateur et/ou résilier l'accès d'un
                    utilisateur pour télécharger un tel matériel en violation
                    des présentes Conditions d'utilisation à tout moment, sans
                    préavis et à sa seule discrétion.<br /><br />

                    <strong>Avertissement</strong><br />
                    Toutes les informations fournies sur ce site web sont
                    fournies «en l'état» avec tous les défauts sans garantie
                    d'aucune sorte, expresse ou implicite. La Haute
                    Société/kitchomaton.fr rejette toute garantie, expresse ou
                    implicite, y compris, sans s'y limiter, celles de qualité
                    marchande, d'adéquation à un usage particulier et de
                    non-violation ou découlant d'un cours de commerce, d'usage
                    ou de pratique commerciale.<br />
                    La Haute Société/kitchomaton.fr ne garantit pas que (i) le
                    service répondra à vos exigences spécifiques, (ii) le
                    service sera ininterrompu, opportun, sécurisé ou sans
                    erreur, (iii) les résultats qui peuvent être obtenus de
                    l'utilisation du service seront être précis ou fiable, (iv)
                    la qualité des produits, services, informations ou autres
                    matériels achetés ou obtenus par le biais du service
                    répondra à vos attentes, et (v) toute erreur dans le Service
                    sera corrigée.<br /><br />

                    <strong>Cookies</strong><br />
                    En naviguant sur ce site vous acceptez le dépôt de cookies
                    utilisés pour réaliser des études statistiques de
                    consultation du site.<br /><br />

                    <strong>Modification</strong><br />
                    Si vous souhaitez faire supprimer vos photos de nos
                    serveurs, merci de contacter
                    <a href="mailto:contact@lahautesociete.com"
                        >contact@lahautesociete.com</a
                    >
                    <br /><br />

                    <strong>Crédits</strong><br />
                    Conception, Direction artistique, photographies originales,
                    développement : La Haute Société<br />
                    Coiffure : Patrick Faure<br />
                    Maquillage : Paula Ruiz<br /><br />

                    Dernière modification : 31 janvier 2018<br /><br />
                </div>

                <router-link
                    :to="{ name: 'index' }"
                    class="button"
                    @click.prevent="toggleMention"
                    >Retour</router-link
                >
            </div>
        </div>

        <div class="footer-component legal-terms-page__footer">
            <div class="footer-component__left"></div>
            <div class="footer-component__right">
                réalisé par
                <a href="//www.lahautesociete.com" target="_blank"
                    >La Haute Société</a
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "legal-terms-page",
    data() {
        return {};
    },
};
</script>

<style scoped lang="scss">
@import "~@/styles/main.scss";

.legal-terms-page {
    position: relative;
    @include size(100vw, auto);
    min-height: 100vh;
    overflow: hidden;
    background-image: linear-gradient(
        0deg,
        rgba(103, 254, 198, 1) 0%,
        rgba(253, 147, 240, 1) 100%
    );
    padding-bottom: 75px;

    &__logo {
        @include sprite;
        @include sprite-logo-kitcho(0.5);
        margin: 5% auto 22px;

        @media (max-width: $mobile-max-breakpoint) {
            transform: scale(0.5);
            margin: 1% auto 0px;
        }
    }

    &__content {
        @include flex(column, flex-start, center);
    }

    &__mention-content {
        max-width: 920px;
        padding: 0 40px;
        @include flex(column, center, flex-start);

        @extend %arial;
        font-size: 12px;

        strong {
            font-size: 14px;
        }
    }

    &__footer {
        box-sizing: border-box;
        width: 100%;
        @include bottomLeft(40px, 50%);
        transform: translateX(-50%);
    }
}
</style>
