/* global FB */
export default {
    /**
     * Init facebook share
     */
    initFacebookShare() {
        window.fbAsyncInit = function () {
            FB.init({
                appId: "315430182300807",
                autoLogAppEvents: true,
                status: true,
                xfbml: true,
                version: "v2.11",
            });
        };

        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
    },

    /**
     * Share URL on Facebook
     * @param url
     */
    share(url, hashtag) {
        FB.ui(
            {
                method: "share",
                mobile_iframe: false,
                href: url,
                hashtag: hashtag,
            },
            () => {}
        );
    },
};
