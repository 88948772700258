<template>
    <div class="photo-component">
        <!-- CAROUSEL -->
        <v-touch
            v-on:swipeleft="updateCurrentKitcho(1)"
            v-on:swiperight="updateCurrentKitcho(-1)"
            v-bind:swipe-options="{ direction: 'horizontal' }"
        >
            <div class="photo-component__carousel start" ref="carousel">
                <div
                    class="photo-component__kitcho kitcho"
                    :key="index"
                    v-for="(kitcho, index) in kitchos"
                    ref="kitcho"
                >
                    <div class="kitcho__image">
                        <div
                            class="kitcho__preview"
                            :data-index="index"
                            @click="zoom"
                        >
                            <img
                                :src="kitcho.overviewImage.url"
                                alt="kitcho.name"
                            />
                        </div>
                        <div class="kitcho__webcam">
                            <video class="kitcho__cam" ref="webcam"></video>
                        </div>
                        <div
                            class="kitcho__loading"
                            v-if="loading && index == currentKitchoIndex"
                        >
                            <div class="kitcho__loading-circle"></div>
                        </div>
                        <div
                            class="kitcho__generated"
                            @click="zoom"
                            :data-index="index"
                            ref="generated"
                        >
                            <img src="" ref="generatedImage" />
                        </div>
                    </div>

                    <div
                        class="kitcho__webcam-cheese"
                        ref="cheese"
                        @click="takeShot"
                    ></div>

                    <div
                        class="kitcho__button"
                        @click="openFileUploadDialog"
                        ref="button"
                    >
                        <span class="kitcho__button-icon"></span>incrustez-vous
                        !
                    </div>

                    <div class="kitcho__happy-new-year" @click="nativeShare()">
                        <div class="kitcho__hny-icon"></div>
                    </div>

                    <div class="kitcho__share">
                        <a
                            href=""
                            class="kitcho__download"
                            target="_blank"
                            ref="download"
                            @click="downloadKitcho()"
                        >
                            <div class="kitcho__down-icon"></div>
                        </a>
                        <a
                            class="kitcho__twitter"
                            ref="twitter"
                            target="_blank"
                            href=""
                            @click="shareToTwitter()"
                        >
                            <div class="kitcho__twitter-icon"></div>
                        </a>
                        <div
                            class="kitcho__facebook"
                            ref="facebook"
                            @click="shareToFacebook"
                        >
                            <div class="kitcho__facebook-icon"></div>
                        </div>
                    </div>
                </div>
            </div>
        </v-touch>

        <div class="photo-component__restart" ref="restart" @click="reset">
            recommencer
        </div>

        <!-- ARROWS -->
        <div
            class="photo-component__arrow photo-component__arrow--left"
            @click="updateCurrentKitcho(-1)"
        ></div>
        <div
            class="photo-component__arrow photo-component__arrow--right"
            @click="updateCurrentKitcho(1)"
        ></div>
        <div
            class="photo-component__arrow photo-component__arrow--rollover photo-component__arrow--left"
            @click="updateCurrentKitcho(-1)"
        ></div>
        <div
            class="photo-component__arrow photo-component__arrow--rollover photo-component__arrow--right"
            @click="updateCurrentKitcho(1)"
        ></div>

        <!-- Hidden -->
        <canvas ref="webcamCanvas" style="display: none"></canvas>
        <canvas ref="stageCanvas" style="display: none"></canvas>
        <input
            style="display: none"
            ref="fileUploadInput"
            type="file"
            accept="image/*"
            capture
            class="fileUploadInput"
            @change="loadUserImage($event.target.files)"
        />
    </div>
</template>

<script>
import { Howl, Howler } from "howler";
import ImageService from "@/app/services/ImageService";
import FaceswapService from "@/app/services/FaceswapService";
import FacebookShareService from "@/app/services/FacebookShareService";
import "@/app/helpers/getusermedia-polyfill.js";
import { gsap, Power2 } from "gsap";

export default {
    name: "photo-component",

    props: ["mute"],

    data() {
        return {
            kitchos: {},
            currentKitchoIndex: 3,
            uploadId: null,
            generateds: [],
            displayAllRelease: false,

            loading: false,
            loaded: false,
            renderImageWidth: 800,
            renderImageHeight: 1200,
            previewImageWidth: null,
            previewImageHeight: null,
            stage: null,
            stageTapped: false,

            slideWidth: 372,
            mobileMaxBreakpoint: 559,

            zoomed: false,
            streaming: false,
            desktop:
                !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                ),
            twitterLink:
                "https://twitter.com/intent/tweet?hashtags=kitchomaton&via=lahautesociete",

            currentSound: false,
            soundBuffer: false,
            soundVolume: 0.2,
        };
    },

    watch: {
        mute() {
            // Change the global volume
            Howler.volume = this.mute ? 0 : this.soundVolume;
        },

        loaded() {
            gsap.set(this.$refs.generated, {
                display: "block",
            });

            let imageName = "";
            let imageUrl = "";
            let shareUrl = "";

            if (this.loaded) {
                imageName = this.generateds[this.currentKitchoIndex].imageName;
                imageUrl =
                    this.generateds[this.currentKitchoIndex].generatedImage.url;
                shareUrl = this.generateds[this.currentKitchoIndex].share.url;
            }

            // Replace generated image
            this.$refs.kitcho[this.currentKitchoIndex]
                .querySelector(".kitcho__download")
                .setAttribute("href", imageUrl);
            this.$refs.kitcho[this.currentKitchoIndex]
                .querySelector(".kitcho__download")
                .setAttribute("download", imageName);
            this.$refs.kitcho[this.currentKitchoIndex]
                .querySelector(".kitcho__twitter")
                .setAttribute("href", this.twitterLink + "&url=" + shareUrl);
            this.$refs.kitcho[this.currentKitchoIndex]
                .querySelector(".kitcho__image .kitcho__generated img")
                .setAttribute("src", imageUrl);

            // Remove start class
            document
                .querySelector(".photo-component__carousel")
                .classList.remove("start");

            // Set white background to current kitcho
            gsap.to(this.$refs.kitcho[this.currentKitchoIndex], {
                duration: 0.3,
                backgroundColor: this.loaded ? "white" : "transparent",
            });

            // Hide the buttons
            gsap.to(document.querySelectorAll(".kitcho"), {
                duration: 0.3,
                className: "kitcho nearCurrent",
            });

            gsap.to(this.$refs.cheese[this.currentKitchoIndex], {
                duration: 0.4,
                opacity: !this.loaded && this.desktop ? 1 : 0,
                display: !this.loaded && this.desktop ? "block" : "none",
            });

            // Hide the buttons
            gsap.to(
                document.querySelectorAll(".kitcho")[this.currentKitchoIndex],
                {
                    duration: 0.3,
                    className: !this.loaded
                        ? "kitcho current"
                        : "kitcho nearCurrent",
                }
            );

            // Show happy new year
            gsap.fromTo(
                this.$refs.kitcho[this.currentKitchoIndex].querySelector(
                    ".kitcho__happy-new-year"
                ),
                {
                    y: this.loaded ? +20 : 0,
                },
                {
                    duration: 0.3,
                    display: this.loaded ? "flex" : "none",
                    opacity: this.loaded ? 1 : 0,
                    y: 0,
                    ease: Power2.easeOut,
                }
            );

            // Show the share
            let shareTl = new gsap.timeline();

            shareTl.to(
                this.$refs.kitcho[this.currentKitchoIndex].querySelector(
                    ".kitcho__share"
                ),
                0.4,
                {
                    opacity: this.loaded ? 1 : 0,
                    display: this.loaded ? "flex" : "none",
                }
            );

            shareTl
                .staggerFromTo(
                    this.$refs.kitcho[this.currentKitchoIndex].querySelectorAll(
                        ".kitcho__share > div"
                    ),
                    0.4,
                    {
                        opacity: this.loaded ? 0 : 1,
                    },
                    {
                        opacity: this.loaded ? 1 : 0,
                    },
                    0.1
                )
                .play();

            gsap.to(this.$refs.restart, {
                duration: 0.4,
                opacity: this.loaded ? 1 : 0,
                pointerEvents: this.loaded ? "auto" : "none",
            });
        },
    },

    methods: {
        /**
         * Start webcam
         */
        startWebcam() {
            this.$ga.event("webcam", "start");
            this.zoomed = true;

            const video =
                document.querySelectorAll(".kitcho__cam")[
                    this.currentKitchoIndex
                ];

            navigator.getMedia =
                navigator.getUserMedia ||
                navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia ||
                navigator.msGetUserMedia;

            console.log(navigator.getMedia);

            if (navigator.getMedia != undefined) {
                navigator.getMedia(
                    {
                        video: true,
                        audio: false,
                    },
                    (stream) => {
                        if (navigator.mozGetUserMedia) {
                            video.mozSrcObject = stream;
                        } else {
                            window.URL =
                                window.URL ||
                                window.webkitURL ||
                                window.mozURL ||
                                window.msURL;
                            video.srcObject = stream;
                        }
                        video.play();
                        this.$ga.event("webcam", "play");
                    },
                    (err) => {
                        this.launchFileUpload();
                        this.$ga.event("webcam", "error");
                        console.log("ERROR : " + err);
                    }
                );

                video.addEventListener(
                    "canplay",
                    () => {
                        if (!this.streaming) {
                            this.streaming = true;
                            this.$ga.event("webcam", "stream");

                            gsap.to(this.$refs.button, {
                                duration: 0.4,
                                opacity: 0,
                                display: "none",
                            });

                            gsap.fromTo(
                                this.$refs.cheese[this.currentKitchoIndex],
                                {
                                    x: -30,
                                    opacity: 0,
                                },
                                {
                                    duration: 0.4,
                                    x: 0,
                                    opacity: 1,
                                    display: "block",
                                }
                            );
                        }
                    },
                    false
                );
            } else {
                this.launchFileUpload();
            }
        },

        /**
         * Open the file upload
         */
        launchFileUpload() {
            this.desktop = false;
            this.openFileUploadDialog();
        },

        takeShot() {
            const video =
                document.querySelectorAll(".kitcho__cam")[
                    this.currentKitchoIndex
                ];
            const canvas = this.$refs.stageCanvas;

            this.$ga.event("webcam", "take-shot");

            video.pause();

            canvas.setAttribute("width", video.clientWidth);
            canvas.setAttribute("height", video.clientHeight);

            canvas
                .getContext("2d")
                .drawImage(video, 0, 0, video.clientWidth, video.clientHeight);
            const data = canvas.toDataURL("image/jpeg");

            // Upload image
            this.$ga.event("upload", "webcam");
            this.uploadImage(data).then((response) => {
                this.receiveFaceswapRespone(response);
            });
        },

        zoom(pEvent) {
            const parent = pEvent.target.parentNode;

            if (this.currentKitchoIndex == parent.getAttribute("data-index")) {
                this.$ga.event("action", "zoom");

                const actualWidth = parent.clientWidth;
                let scaleValue = 1.7;
                scaleValue =
                    actualWidth * scaleValue > window.innerWidth
                        ? (window.innerWidth - 4) / actualWidth
                        : scaleValue;

                // Scale the photo
                gsap.to(parent, {
                    duration: 0.5,
                    scale: !this.zoomed ? scaleValue : 1,
                    ease: Power2.easeOut,
                });

                // Change the z-index to go over
                gsap.set(this.$refs.kitcho[this.currentKitchoIndex], {
                    zIndex: !this.zoomed ? 1000 : "inherit",
                });

                gsap.set(this.$refs.kitcho[this.getIndex(1)], {
                    zIndex: !this.zoomed ? 0 : "inherit",
                });

                gsap.to(
                    [
                        document.querySelectorAll(".kitcho__button"),
                        document.querySelectorAll(".photo-component__arrow"),
                    ],
                    {
                        duration: 0.2,
                        opacity: !this.zoomed ? 0 : 1,
                    }
                );

                gsap.to(
                    [
                        parent.parentNode.parentNode.querySelector(
                            ".kitcho__share"
                        ),
                        parent.parentNode.parentNode.querySelectorAll(
                            ".kitcho__happy-new-year"
                        ),
                    ],
                    {
                        duration: 0.2,
                        opacity: !this.zoomed ? 0 : 1,
                    }
                );

                this.zoomed = !this.zoomed;
            }
        },

        /**
         * Reset
         */
        reset() {
            // Set loaded to false
            this.loaded = false;
            this.loading = false;

            // Reset the uploadId
            this.uploadId = null;

            // Empty the generateds
            this.generateds = [];

            // Remvoe all the generateds loaded file
            Array.prototype.forEach.call(
                this.$refs.generatedImage,
                function (el) {
                    el.setAttribute("src", "");
                }
            );

            this.openFileUploadDialog();
        },

        /**
         * Get all the kitchos from the server
         */
        loadKitchos() {
            return FaceswapService.getKitchos()
                .then((data) => {
                    this.kitchos = data.filter((kitcho) => {
                        return kitcho.release === 1 || this.displayAllRelease;
                    });
                })
                .then(() => {
                    for (const key in this.kitchos) {
                        if (this.kitchos[key].start) {
                            this.currentKitchoIndex = parseInt(key);
                            this.slideToCurrentIndex();
                            this.updateKitchosState();
                        }
                    }
                });
        },

        /**
         * Update the current kitcho within the direction passed
         * @param files
         */
        updateCurrentKitcho(pDirection) {
            this.$ga.event("action", "change-kitcho");

            pDirection = pDirection == "swipeleft" ? 1 : pDirection;
            pDirection = pDirection == "swiperight" ? -1 : pDirection;

            if (this.zoomed) {
                return;
            }
            // Update the current kitcho index
            this.currentKitchoIndex = this.getIndex(pDirection);

            // Animate the changement
            this.slideToCurrentIndex();

            // Update the kitchos around
            this.updateKitchosState();

            // Remove start class
            document
                .querySelector(".photo-component__carousel")
                .classList.remove("start");

            // Change the index background
            this.$emit(
                "COLOR_CHANGE",
                this.kitchos[this.currentKitchoIndex].gradient.backgroundImage,
                this.kitchos[this.currentKitchoIndex].gradient.backgroundColor
            );

            // If we already load the photo
            if (this.loaded) {
                // Preload the next
                this.preloadGenerateds();
            }

            // If we are not muted
            if (!this.mute) {
                // If we have a previous sound playing
                if (this.currentSound && this.soundBuffer) {
                    // Fade it
                    this.currentSound.fade(
                        this.soundVolume,
                        0,
                        1000,
                        this.soundBuffer
                    );
                }

                // Change current sound
                this.currentSound = new Howl({
                    src: [
                        this.kitchos[this.currentKitchoIndex].audio[
                            Math.floor(
                                Math.random() *
                                    Math.floor(
                                        this.kitchos[this.currentKitchoIndex]
                                            .audio.length - 1
                                    )
                            )
                        ],
                    ],
                    volume: this.soundVolume,
                });

                // Play it in the buffer
                this.soundBuffer = this.currentSound.play();
            }
        },

        /**
         * Return the index within the change pDirection
         * @param pDirection
         */
        getIndex(pDirection) {
            // Set index like the current kitcho index
            let index = this.currentKitchoIndex;

            // Add pDirection
            index += pDirection;

            // If we are at the end, go back to 0
            index = index > this.kitchos.length - 1 ? 0 : index;

            // If we are at the beginning, go back to the end
            index = index < 0 ? this.kitchos.length - 1 : index;

            return index;
        },

        getKitchosSlice(pValue) {
            let selectedKitchos = [];

            for (let kitchoIndex in this.kitchos) {
                if (
                    kitchoIndex < this.currentKitchoIndex - pValue ||
                    kitchoIndex > this.currentKitchoIndex + pValue
                ) {
                    selectedKitchos.push(
                        document.querySelectorAll(".kitcho")[kitchoIndex]
                    );
                }
            }

            return selectedKitchos;
        },

        slideToCurrentIndex() {
            gsap.to(this.$refs.kitcho, {
                duration: 0.3,
                x: -this.slideWidth * this.currentKitchoIndex,
            });
        },

        /**
         * Change the kitchos within the current kitcho index
         */
        updateKitchosState() {
            // Reset all kitchos
            gsap.set(document.querySelectorAll(".kitcho"), {
                className: "kitcho",
            });

            // Hide all the share and happy new year
            gsap.set(
                [
                    document.querySelectorAll(".kitcho__happy-new-year"),
                    document.querySelectorAll(".kitcho__share"),
                ],
                {
                    opacity: 0,
                }
            );

            // Set the opacity for the +2 and -2
            gsap.to(this.getKitchosSlice(1), {
                duration: 0.4,
                opacity: 0.1,
                className: "kitcho nearCurrent",
            });

            // Set the opacity for the -1 and +1
            gsap.to(
                [
                    document.querySelectorAll(".kitcho")[this.getIndex(1)],
                    document.querySelectorAll(".kitcho")[this.getIndex(-1)],
                ],
                {
                    duration: 0.4,
                    opacity: 0.3,
                    className: "kitcho nearCurrent",
                    background: "transparent",
                }
            );

            // Set the opacity for the current
            gsap.to(
                document.querySelectorAll(".kitcho")[this.currentKitchoIndex],
                {
                    duration: 0.4,
                    opacity: 1,
                    className: !this.loaded
                        ? "kitcho current"
                        : "kitcho nearCurrent",
                    backgroundColor: this.loaded ? "white" : "transparent",
                }
            );

            // Show the happy new year message
            gsap.fromTo(
                document
                    .querySelectorAll(".kitcho")
                    [this.currentKitchoIndex].querySelector(
                        ".kitcho__happy-new-year"
                    ),
                {
                    y: this.loaded ? 0 : +50,
                },
                {
                    duration: 0.3,
                    display: this.loaded ? "flex" : "none",
                    opacity: this.loaded ? 1 : 0,
                    y: 0,
                    ease: Power2.easeOut,
                }
            );

            if (this.loaded) {
                // Show the share
                let shareTl = new gsap.timeline();

                shareTl.to(
                    this.$refs.kitcho[this.currentKitchoIndex].querySelector(
                        ".kitcho__share"
                    ),
                    0.4,
                    {
                        opacity: 1,
                        display: "flex",
                    }
                );

                shareTl
                    .staggerFromTo(
                        this.$refs.kitcho[
                            this.currentKitchoIndex
                        ].querySelectorAll(".kitcho__share > *"),
                        0.4,
                        {
                            opacity: 0,
                        },
                        {
                            opacity: 1,
                        },
                        0.1
                    )
                    .play();
            }
        },

        /**
         * Preload the next and previous generated images within the current kitcho index
         */
        preloadGenerateds() {
            // No uploadId defined
            if (!this.uploadId) {
                return;
            }

            // Load previous
            this.preloadGeneratedFromId(this.getIndex(-1));

            // Load next
            this.preloadGeneratedFromId(this.getIndex(1));
        },

        /**
         * Preload generated kitcho from id
         */
        preloadGeneratedFromId(id) {
            // Do not load if already loaded
            if (this.generateds[id]) {
                return;
            }

            FaceswapService.swapFromUploadId(
                this.kitchos[id].name,
                this.uploadId
            )
                .then((response) => {
                    this.generateds[id] = response.data;
                    this.updateKitchoValues(
                        this.$refs.kitcho[id],
                        this.generateds[id]
                    );
                })
                .catch((error) => {
                    console.log(
                        "Erreur dans le chargement de la photo " + id,
                        error
                    );
                });
        },

        /**
         * Update kitcho DOM
         **/
        updateKitchoValues($kitcho, values) {
            $kitcho
                .querySelector(".kitcho__image .kitcho__generated img")
                .setAttribute("src", values.generatedImage.url);
            $kitcho
                .querySelector(".kitcho__download")
                .setAttribute("href", values.generatedImage.url);
            $kitcho
                .querySelector(".kitcho__download")
                .setAttribute("download", values.imageName);
            $kitcho
                .querySelector(".kitcho__twitter")
                .setAttribute(
                    "href",
                    this.twitterLink + "&url=" + values.share.url
                );
        },

        /**
         * Load user image
         * @param files
         */
        loadUserImage(files) {
            this.stageTapped = true;
            this.loading = true;
            const file = files[0];
            const maxDimension = 1000;

            // Display image
            return (
                ImageService.drawImage(
                    file,
                    maxDimension,
                    this.stageCanvas,
                    this.stageCanvasContext
                )
                    // Upload image
                    .then(() => {
                        this.$ga.event("upload", "file");
                        return this.uploadImage();
                    })

                    // Response from API
                    .then((response) => {
                        this.receiveFaceswapRespone(response);
                    })
            );
        },

        /**
         * Upload image
         */
        uploadImage(dataURL) {
            // Get data from stageCanvas if not defined
            if (!dataURL) {
                dataURL = this.stageCanvas.toDataURL("image/jpeg");
                this.$ga.event("upload", "file");
            }

            const file = ImageService.transformDataUrlToBlob(dataURL);

            return FaceswapService.swapFromFile(
                this.kitchos[this.currentKitchoIndex].name,
                file
            );
        },

        receiveFaceswapRespone(response) {
            if (!response.data.success) {
                this.$ga.event("upload", "no-face");
                alert(
                    "Désolé aucun visage n'a pu être détecté. Conseils: une seule personne sur la photo, prendre la photo de face. Merci de réessayer"
                );
                this.reset();
                return;
            }
            this.$ga.event("upload", "success");

            this.zoomed = false;

            // Save the upload ID
            this.uploadId = response.data.uploadId;

            // Save the generated
            this.generateds[this.currentKitchoIndex] = response.data;

            this.loading = false;
            this.loaded = true;

            // Preload the next and previous generateds
            setTimeout(() => this.preloadGenerateds(), 1);
        },

        /**
         * Open file upload dialog
         */
        openFileUploadDialog() {
            if (this.desktop) {
                this.startWebcam();
            } else {
                this.$refs.fileUploadInput.click();
            }
        },

        /**
         * Share current kitcho to facebook
         */
        shareToFacebook() {
            this.$ga.event("share", "facebook");
            const currentKitchoShareUrl =
                this.generateds[this.currentKitchoIndex].share.url;
            FacebookShareService.share(currentKitchoShareUrl, "#kitchomaton");
        },

        nativeShare() {
            if (!navigator.share || this.desktop) {
                return;
            }
            const currentKitchoShareUrl =
                this.generateds[this.currentKitchoIndex].share.url;
            this.$ga.event("share", "native-android");

            navigator.share({
                title: "Kitchomaton : en route pour les années 80 !",
                text: "#kitchomaton : tapez-vous l’incruste dans le #Kitchomaton : direction les années 80 en compagnie des membres de @lahautesociete.",
                url: currentKitchoShareUrl,
            });
        },

        downloadKitcho() {
            this.$ga.event("share", "download");
        },

        shareToTwitter() {
            this.$ga.event("share", "twitter");
        },
    },

    /**
     * Component mounted
     */
    mounted() {
        FacebookShareService.initFacebookShare();

        // Get the kitchos from the server
        this.loadKitchos();

        // Keep reference to stage canvas
        // Used to manipulate user image from file
        this.stageCanvas = this.$refs.stageCanvas;
        this.stageCanvasContext = this.stageCanvas.getContext("2d");

        // this.slideToCurrentIndex();

        // Change global volume
        Howler.volume(this.soundVolume);

        this.currentSound = new Howl({
            src: "sound/intro.mp3",
            volume: this.soundVolume,
        });

        // Play it in the buffer
        this.soundBuffer = this.currentSound.play();

        // Breakpoints
        window.addEventListener("resize", () => {
            this.slideWidth =
                window.innerWidth <= this.mobileMaxBreakpoint ? 340 : 372;
        });
        this.slideWidth =
            window.innerWidth <= this.mobileMaxBreakpoint ? 340 : 372;
    },
};
</script>

<style scoped lang="scss">
@import "~@/styles/main.scss";

$kitcho-width: 332px;

.photo-component {
    position: relative;
    width: 100%;

    &__stageContainer {
        @include size(100%, 389px);
        @include topLeft(0, 0);
        transform: translateY(-200%);
        overflow: hidden;
        flex: 1 1;
        opacity: 0;
    }

    &__stageCanvas {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 2px solid $color-white;
    }

    &__carousel {
        position: relative;
        width: 100%;
        overflow: visible;
        margin-left: calc(50vw - 166px);
        @include flex(row, flex-start, center);

        @media (max-width: $mobile-max-breakpoint) {
            margin-left: calc(50vw - 150px);
        }

        &.start {
            .kitcho {
                opacity: 0.1;

                &__button {
                    opacity: 0;
                    display: none;
                }
            }
            .kitcho:nth-child(2) {
                opacity: 0.3;
            }
            .kitcho:first-child {
                opacity: 1;

                .kitcho__button {
                    opacity: 1;
                    display: flex;
                }
            }
        }
    }

    &__arrow {
        transform: translateY(calc(-50% - 81px));
        cursor: pointer;
        transition: opacity 0.3s ease-out;
        @include size(300px, 487px);

        @media (max-width: $mobile-max-breakpoint) {
            height: 50px;
        }

        &:before {
            content: "";
            @include sprite;
        }

        &--left {
            @include topLeft(50%, 50%);
            margin-left: -540px;

            @media (max-width: $mobile-max-breakpoint) {
                margin-left: -410px;
            }

            &:before {
                content: "";
                @include sprite-arrow-white-left(0.5);
                @include topRight(50%, 0);
                right: 20px;
                transform: translateY(-50%);
            }
        }

        &--right {
            @include topRight(50%, 50%);
            margin-right: -540px;

            @media (max-width: $mobile-max-breakpoint) {
                margin-right: -410px;
            }

            &:before {
                content: "";
                @include sprite-arrow-white-right(0.5);
                @include topLeft(50%, 0);
                left: 20px;
                transform: translateY(-50%);
            }
        }

        &--rollover.photo-component__arrow--left {
            opacity: 0;

            &:before {
                content: "";
                @include sprite-arrow-black-left(0.5);
            }
        }

        &--rollover.photo-component__arrow--right {
            opacity: 0;

            &:before {
                content: "";
                @include sprite-arrow-black-right(0.5);
            }
        }

        @media (max-width: $mobile-max-breakpoint) {
            top: 38px;
        }

        &:hover {
            opacity: 1;
        }
    }

    &__restart {
        @include flexCenter(row);
        @include size($kitcho-width, 77px);
        margin: 85px auto 0 auto;
        border: 2px solid $color-white;

        @extend %arial-bold;
        font-size: 24px;
        cursor: pointer;
        text-align: center;
        @include disableSelection;

        opacity: 0;
        pointer-events: none;
        transition: color 0.3s;

        @media (max-width: $mobile-max-breakpoint) {
            @include size(300px, 70px);
        }

        &:hover {
            color: $color-white;
        }
    }
}

.kitcho {
    box-sizing: border-box;
    position: relative;
    @include size(auto, 487px);
    padding: 10px 10px 0 10px;
    border: 2px solid $color-white;
    @include flex(column, flex-start, flex-start);

    @media (max-width: $mobile-max-breakpoint) {
        @include size(auto, 443px);
    }

    & + & {
        margin-left: 40px;
    }

    &.nearCurrent {
        .kitcho__button {
            display: none;
            opacity: 0;
            pointer-events: none;
        }
    }

    &.current {
        .kitcho__button {
            display: flex;
            opacity: 1;
            pointer-events: auto;
        }
    }

    &__image {
        @extend %photo-ratio;
    }

    &__preview,
    &__loading,
    &__webcam,
    &__generated {
        @extend %photo-ratio;
        overflow: hidden;
        border: 2px solid $color-white;
        @include topLeft(10px, 10px);

        img {
            width: 100%;
        }
    }

    &__webcam video {
        height: 100%;
        @include topLeft(0, 50%);
        transform: translateX(-50%);
    }

    &__webcam-cheese {
        position: relative;
        @include size(50px, 50px);
        border: 2px solid $color-black;
        border-radius: 50%;
        margin: 15px auto 0 auto;
        cursor: pointer;

        display: none;

        &:before {
            content: "";
            @include sprite;
            @include sprite-photo(0.5);
            @include topLeft(50%, 50%);
            transform: translate(-50%, -50%);
            margin-top: -2px;
        }
    }

    &__generated {
        display: none;
    }

    &__loading {
        @include flexCenter(row);
    }

    &__loading-circle {
        @include sprite;
        @include sprite-circle-intro(0.5);

        animation-duration: 5s;
        animation-name: rotate;
        animation-iteration-count: infinite;
        animation-direction: alternate;

        @keyframes rotate {
            from {
                transform: rotate(0);
            }

            to {
                transform: rotate(360deg);
            }
        }
    }

    &__button {
        @include flexCenter(row);
        width: 100%;
        flex-grow: 2;

        @extend %arial-bold;
        font-size: 24px;
        cursor: pointer;
        text-align: center;
        @include disableSelection;

        transition: color 0.3s ease-out;
    }

    &__button-icon {
        position: relative;
        display: flex;

        &:before {
            content: "";
            @include sprite;
            @include sprite-photo(0.5);
            margin-right: 10px;
            transition: opacity 0.3s ease-out;
        }
        &:after {
            content: "";
            @include sprite;
            @include sprite-photo-white(0.5);
            margin-right: 10px;
            @include topLeft(0, 0);
            opacity: 0;
            transition: opacity 0.3s ease-out;
        }
    }

    &__happy-new-year {
        @include flexCenter(row);
        width: 100%;
        flex-grow: 2;

        opacity: 0;
        display: none;
    }

    &__share {
        @include flex(row, space-between, center);
        @include bottomLeft(-60px, 0);
        width: 100%;
        padding: 0 20px;

        display: none;
        opacity: 0;
    }

    &__facebook {
        cursor: pointer;
    }

    &__hny-icon {
        @include sprite;
        @include sprite-partage(0.5);
    }

    &__down-icon {
        @include sprite;
        @include sprite-download(0.5);
    }

    &__insta-icon {
        @include sprite;
        @include sprite-insta(0.5);
    }

    &__twitter-icon {
        @include sprite;
        @include sprite-twitter(0.5);
    }

    &__facebook-icon {
        @include sprite;
        @include sprite-facebook(0.5);
    }

    &:hover &__button {
        color: $color-white;
    }

    &:hover &__button-icon {
        &:before {
            opacity: 0;
        }
        &:after {
            opacity: 1;
        }
    }
}

%photo-ratio {
    @include size(308px, 388px);

    @media (max-width: $mobile-max-breakpoint) {
        @include size(276px, 343px);
    }
}
</style>
