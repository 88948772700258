import Vue from "vue";
import Router from "vue-router";

import IndexPage from "@/app/pages/IndexPage";
import LegalTermsPage from "@/app/pages/LegalTermsPage";

Vue.use(Router);

export default new Router({
    mode: "hash",
    routes: [
        {
            path: "/",
            name: "index",
            component: IndexPage,
        },
        {
            path: "/mentions-legales",
            name: "legal-terms",
            component: LegalTermsPage,
        },
        {
            path: "*",
            redirect: "index",
        },
    ],
});
