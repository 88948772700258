<template>
    <div class="App">
        <router-view />
        <cnil-component />
    </div>
</template>
<script>
import CnilComponent from "@/app/components/CnilComponent";

export default {
    name: "app",
    components: { CnilComponent },
};
</script>

<style src="@/styles/main.scss" lang="scss"></style>

<style scoped lang="scss">
@import "~@/styles/main.scss";

.App {
    height: 100vh;
}
</style>
